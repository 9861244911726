import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/iPad/Install/PoE/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-poe-modelle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-poe-modelle",
        "aria-label": "hinzufügen einer neuen ip kamera poe modelle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`PoE Modelle`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c7b7cdbd83e771c820d5026666147e8/49853/iPad_Wizard_Installation_PoE_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOUlEQVQoz5WSbU/DMAyE+///JiABbZrZTvwWp0EbrIwOEJyeT45OcXI3vSyneUmPjw8pJVUtVXPt5r21M+4RsSEWxBKxvQ8jtkL14TlPJyRmVlVmjghRz2ilWr3C7AAFoDL7PhS2TG1a15RSGldFC0IB+AKiIurXISPwBACllDHGdlFrgSh/ZMo5E9EYo/f+rZlId74xp5S2q9pl7UJCl2MAXhaY5zzPp2UBAL5ligh338299wX1OesCqmrurVYphUvhWtk9dkRjGnfCapm0VGnNx89qMabtqJ6LzSAqwswi8pPZ780RcV6SGPEMEdebzG8p1Y7m+98+ZH7L8c0R/R85/3LzuUZ/Me8N6xH1GvKhGESftfkwm5mIpJRq5eb2Cva0eiJ3b+t6yhlE1MwBiKiauYgiFhEz8zdE+2RQfTa43wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c7b7cdbd83e771c820d5026666147e8/e4706/iPad_Wizard_Installation_PoE_01.avif 230w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/d1af7/iPad_Wizard_Installation_PoE_01.avif 460w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/7f308/iPad_Wizard_Installation_PoE_01.avif 920w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/e1c99/iPad_Wizard_Installation_PoE_01.avif 1380w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/76ea5/iPad_Wizard_Installation_PoE_01.avif 1840w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/b105c/iPad_Wizard_Installation_PoE_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c7b7cdbd83e771c820d5026666147e8/a0b58/iPad_Wizard_Installation_PoE_01.webp 230w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/bc10c/iPad_Wizard_Installation_PoE_01.webp 460w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/966d8/iPad_Wizard_Installation_PoE_01.webp 920w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/445df/iPad_Wizard_Installation_PoE_01.webp 1380w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/78de1/iPad_Wizard_Installation_PoE_01.webp 1840w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/03b06/iPad_Wizard_Installation_PoE_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c7b7cdbd83e771c820d5026666147e8/81c8e/iPad_Wizard_Installation_PoE_01.png 230w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/08a84/iPad_Wizard_Installation_PoE_01.png 460w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/c0255/iPad_Wizard_Installation_PoE_01.png 920w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/b1001/iPad_Wizard_Installation_PoE_01.png 1380w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/161ec/iPad_Wizard_Installation_PoE_01.png 1840w", "/en/static/9c7b7cdbd83e771c820d5026666147e8/49853/iPad_Wizard_Installation_PoE_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c7b7cdbd83e771c820d5026666147e8/c0255/iPad_Wizard_Installation_PoE_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d6c1c775fad827f5b1cbdd006edf742/49853/iPad_Wizard_Installation_PoE_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB60lEQVQoz32QW2sbMRCF94+3L8EUSsgPaaF9Djg0fWofes+DKUmz3lu8N63uGs1I6y1rxyaGpofDMBzpY0ZKml50PSvLgjHmvTfOczuCj0SzQxitdU3Tdl1vLYQwEoUQRq1NXg+JUNo55713zsUYwZMwBBARo/cBMSplm6Zv214pgzh6HzxGwlHamAwDY4xNB8U49h3P801RbLLsoSjq+/siTcs0nWtR1EVRZ1mV55uu5YmU0hgzTdM4brfbbQixqpo0LbOsStNyva7y/GG1ulutbtfrxyRNi/W6rKo24ZxrrXfwuIeNQWvpaOeC1t4YdC48zY3BGWaMbQ8KISoFR0vphDBSumEwXSee5kpBEmMkomdgzwd1n/flZrj5tfr0+UvfCa398UIynSrG8WSysFXN62b4fZt9vfnTd/wE3p7qOFlIGCRwCV2vBm4Zt3UrhfJczkf/hSVEdFPcG6bgZkfYJ9G7Z2GpwBp48w1fXIbFVThbhldXs8+WtLgKLy/D2+/oDMh/vnmGNbz7gYslnV/T6w908ZEuds35NS2W9P7nAX5u7eB3q9Kh7pudA5yuPU3TvhljtBq0AiFASC+k5wL4aS/V44cniAgAjDFrXSBsJVYDMU1EYRgE5xLAI5KUWmuLSABeKQOAiPQXKBJO+7pnMuEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d6c1c775fad827f5b1cbdd006edf742/e4706/iPad_Wizard_Installation_PoE_02.avif 230w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/d1af7/iPad_Wizard_Installation_PoE_02.avif 460w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/7f308/iPad_Wizard_Installation_PoE_02.avif 920w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/e1c99/iPad_Wizard_Installation_PoE_02.avif 1380w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/76ea5/iPad_Wizard_Installation_PoE_02.avif 1840w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/b105c/iPad_Wizard_Installation_PoE_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d6c1c775fad827f5b1cbdd006edf742/a0b58/iPad_Wizard_Installation_PoE_02.webp 230w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/bc10c/iPad_Wizard_Installation_PoE_02.webp 460w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/966d8/iPad_Wizard_Installation_PoE_02.webp 920w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/445df/iPad_Wizard_Installation_PoE_02.webp 1380w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/78de1/iPad_Wizard_Installation_PoE_02.webp 1840w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/03b06/iPad_Wizard_Installation_PoE_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d6c1c775fad827f5b1cbdd006edf742/81c8e/iPad_Wizard_Installation_PoE_02.png 230w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/08a84/iPad_Wizard_Installation_PoE_02.png 460w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/c0255/iPad_Wizard_Installation_PoE_02.png 920w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/b1001/iPad_Wizard_Installation_PoE_02.png 1380w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/161ec/iPad_Wizard_Installation_PoE_02.png 1840w", "/en/static/3d6c1c775fad827f5b1cbdd006edf742/49853/iPad_Wizard_Installation_PoE_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d6c1c775fad827f5b1cbdd006edf742/c0255/iPad_Wizard_Installation_PoE_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_PoE_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVQoz62S3WqEQAyFJ5k1Y36G0VQUvRF8/4csjHRxi4tu6XeZ5JCTcEJ4EBCHP4IR4iP8L3Dg1jQztz+4+zAMXxURedZjjGf2EVUVEWMFEQEAK88KEbVtey4+bxyIMaaUrsWI2FSOPq/FXBnHcV3XbdumaZLKLXHO2d1LKSKSczazvu/d/QPbH9/MzJfi8wUAYGbMbGYiwq/sLRFpmuZtThBxWRYi+mVqnueU0nXa9hgxcyml6zoz20+9ldMQAhGpqlVU9d0vvgHlHwsUrtTI2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e4706/iPad_Wizard_Installation_PoE_03.avif 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/d1af7/iPad_Wizard_Installation_PoE_03.avif 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/7f308/iPad_Wizard_Installation_PoE_03.avif 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e1c99/iPad_Wizard_Installation_PoE_03.avif 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/76ea5/iPad_Wizard_Installation_PoE_03.avif 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b105c/iPad_Wizard_Installation_PoE_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/a0b58/iPad_Wizard_Installation_PoE_03.webp 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/bc10c/iPad_Wizard_Installation_PoE_03.webp 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/966d8/iPad_Wizard_Installation_PoE_03.webp 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/445df/iPad_Wizard_Installation_PoE_03.webp 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/78de1/iPad_Wizard_Installation_PoE_03.webp 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/03b06/iPad_Wizard_Installation_PoE_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/81c8e/iPad_Wizard_Installation_PoE_03.png 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/08a84/iPad_Wizard_Installation_PoE_03.png 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_PoE_03.png 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b1001/iPad_Wizard_Installation_PoE_03.png 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/161ec/iPad_Wizard_Installation_PoE_03.png 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_PoE_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_PoE_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/49853/iPad_Wizard_Installation_PoE_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBklEQVQoz52TYW8DIQiG/f+/csn6pVdB1KuIgrfkzG7Nmuy6Pt94w0sA0V093TxcLp8AUGvNd4YstyQ+1cLMXEQEABBRRHin1kpEy7I4SrmUUmstpfSuVfoCkXLhZq313ruqTo+q9m9U1cwcIgDA9s0YA/xtzWn7k7HjYozruh6xmeGOmY0zXAgh57xt28w2M+99COEod2IGgCM2sxBCSmm8gFPV1tqj5L1HxJfMz8tAxJROFjZxv4r9r+1nMxHFGN8xz5mJ6J2ZxxiISETvzDxseA/MfLz8eduPJ0EBK5epHPrjVf6c5/wrAHC/l95kifKxrFdcpbWww8wiEmPMOc/klNIUvwDylHHcJey8wAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/e4706/iPad_Wizard_Installation_PoE_04.avif 230w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/d1af7/iPad_Wizard_Installation_PoE_04.avif 460w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/7f308/iPad_Wizard_Installation_PoE_04.avif 920w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/e1c99/iPad_Wizard_Installation_PoE_04.avif 1380w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/76ea5/iPad_Wizard_Installation_PoE_04.avif 1840w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/b105c/iPad_Wizard_Installation_PoE_04.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/a0b58/iPad_Wizard_Installation_PoE_04.webp 230w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/bc10c/iPad_Wizard_Installation_PoE_04.webp 460w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/966d8/iPad_Wizard_Installation_PoE_04.webp 920w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/445df/iPad_Wizard_Installation_PoE_04.webp 1380w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/78de1/iPad_Wizard_Installation_PoE_04.webp 1840w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/03b06/iPad_Wizard_Installation_PoE_04.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/81c8e/iPad_Wizard_Installation_PoE_04.png 230w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/08a84/iPad_Wizard_Installation_PoE_04.png 460w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/c0255/iPad_Wizard_Installation_PoE_04.png 920w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/b1001/iPad_Wizard_Installation_PoE_04.png 1380w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/161ec/iPad_Wizard_Installation_PoE_04.png 1840w", "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/49853/iPad_Wizard_Installation_PoE_04.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd9ef0bb4eb4d75d3025ecdabed2d7c8/c0255/iPad_Wizard_Installation_PoE_04.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8003FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/49853/iPad_Wizard_Installation_PoE_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQoz4WSS47UQBBEfTRYIo0Eh4JLzAKN5hpwEJDYDN1ud7Wd9XFmVdYny0b20M24mU/oLVJRClWGlI0abD/Afr8DgBgjhah9DbFKqaXUnKXWCdET+VqnspoiE6HfHU1jRwwhxBhDCEWEY9ZjQkreX4hKDUqB9/FickgGS6M1AMB8lhTBkZ37h7UBYBwGZ2144ofRhcY5R0TzPE+rSpFx5KcgRqJElBDj1VNjjEHEeZ5rrc+GjSGt0Wg0hp4JA8B01lXYudC2ar/ruq7vDr3d5hsRyTm/Ej60p+7Q970Zemut34TnrUTq1W7Hbvj549d+dxwGd732tNX/na3zbatOSl/5r4UJ2dNCIGYfA7E/O4RvhcGy0nxaOcLC46z08vRiZ+eYiT9/y+9u5eZOPnyVT/fy8X4Zbu7k/a18+Z6jX07lxZ+7gR8U/z4t7FYe5wfFHWzXvlxYFfHIOC4No4/RR/5be2UdAp3DKSVmBgDvQ8np5FKrM2DOuWhtjXHMMaXsHCL6lDJzHEdiTinlPxXaVMuOxMEOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d6a156a8e0f26f48df8a9024eb8433/e4706/iPad_Wizard_Installation_PoE_05.avif 230w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/d1af7/iPad_Wizard_Installation_PoE_05.avif 460w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/7f308/iPad_Wizard_Installation_PoE_05.avif 920w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/e1c99/iPad_Wizard_Installation_PoE_05.avif 1380w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/76ea5/iPad_Wizard_Installation_PoE_05.avif 1840w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/b105c/iPad_Wizard_Installation_PoE_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0d6a156a8e0f26f48df8a9024eb8433/a0b58/iPad_Wizard_Installation_PoE_05.webp 230w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/bc10c/iPad_Wizard_Installation_PoE_05.webp 460w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/966d8/iPad_Wizard_Installation_PoE_05.webp 920w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/445df/iPad_Wizard_Installation_PoE_05.webp 1380w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/78de1/iPad_Wizard_Installation_PoE_05.webp 1840w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/03b06/iPad_Wizard_Installation_PoE_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d6a156a8e0f26f48df8a9024eb8433/81c8e/iPad_Wizard_Installation_PoE_05.png 230w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/08a84/iPad_Wizard_Installation_PoE_05.png 460w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/c0255/iPad_Wizard_Installation_PoE_05.png 920w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/b1001/iPad_Wizard_Installation_PoE_05.png 1380w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/161ec/iPad_Wizard_Installation_PoE_05.png 1840w", "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/49853/iPad_Wizard_Installation_PoE_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0d6a156a8e0f26f48df8a9024eb8433/c0255/iPad_Wizard_Installation_PoE_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db392838d29649d2fcf7cb89b9ff48ca/49853/iPad_Wizard_Installation_PoE_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVQoz5WS627kIAyF8/6vOV1pJ5QQMOArsEoySTuttpoenR+WwweOjqf7++re/dvbzXtPRLlSKA25mTbVJmJmPaWcUjbrujfNeoZy+xumNUGtlYhqrWqGJCExZC7l4VolxhxjqVWuJlYOoNOyeO/9OGVqkDDGJ6dEKdFzs6ZYpxhjznmM0XepWkr4oqcQAgCMMVprn2EA+p/3QU7Ye99PqVqG7YNz6zz7eV6cC/O8HHYu3O/euQCAGXAyMxG54NZaRhNRZqGHmK6KWVV7s0T9tvZpPKv3XokRkXlj8JMO+DhWZcx5TP2bQpZ53c8SHvj17HGdMAGP+3e4tVYqwxYvnX7UiEfBhJxR1yxf4dejgoRf/9ms/SLnH17e1ugV+NqwZlZgG+nak4854SrOJWHegvHel1JVeI78ZxEPIqLLsoYQEYlZYgSAwiyIlFLeo5R/9PhjDTWSYCEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db392838d29649d2fcf7cb89b9ff48ca/e4706/iPad_Wizard_Installation_PoE_06.avif 230w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/d1af7/iPad_Wizard_Installation_PoE_06.avif 460w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/7f308/iPad_Wizard_Installation_PoE_06.avif 920w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/e1c99/iPad_Wizard_Installation_PoE_06.avif 1380w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/76ea5/iPad_Wizard_Installation_PoE_06.avif 1840w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/b105c/iPad_Wizard_Installation_PoE_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db392838d29649d2fcf7cb89b9ff48ca/a0b58/iPad_Wizard_Installation_PoE_06.webp 230w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/bc10c/iPad_Wizard_Installation_PoE_06.webp 460w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/966d8/iPad_Wizard_Installation_PoE_06.webp 920w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/445df/iPad_Wizard_Installation_PoE_06.webp 1380w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/78de1/iPad_Wizard_Installation_PoE_06.webp 1840w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/03b06/iPad_Wizard_Installation_PoE_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db392838d29649d2fcf7cb89b9ff48ca/81c8e/iPad_Wizard_Installation_PoE_06.png 230w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/08a84/iPad_Wizard_Installation_PoE_06.png 460w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/c0255/iPad_Wizard_Installation_PoE_06.png 920w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/b1001/iPad_Wizard_Installation_PoE_06.png 1380w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/161ec/iPad_Wizard_Installation_PoE_06.png 1840w", "/en/static/db392838d29649d2fcf7cb89b9ff48ca/49853/iPad_Wizard_Installation_PoE_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db392838d29649d2fcf7cb89b9ff48ca/c0255/iPad_Wizard_Installation_PoE_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Wählen Sie aus, ob Sie PoE oder das externe Netzteil zur Stromversorgung Ihrer Kamera verwenden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/49853/iPad_Wizard_Installation_PoE_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzklEQVQoz4WSS27bMBCGdbhsg2xzoQI9RBZpFzlF0Xu0qAXoYYWSOKTIITl8jKzCduLGQYP8+BfkP/jI4aMa5TJL6PsOAGKMLkTl1hC5ZM4nHw4bokP067rlzClzKSuia4WqFoshhBhjCIGZKeYFc6ASYyEq3kdrvVJGKWOt9z6d8pwTG8+VUgAA26u48DzrvhP9fhwG2ffjbtfUdVvXXV13fT8OwzwMc9sJIWRljHHObdt2OKkU1tpJaQAsgNXaCSGbZmiavRBSKTznUhqlsNJaI+K2beu6nmHEePGyeDkvSqFz2bn0toQYjzAAHF5VCltLZyMmIeTvX3/6ThgTnIuX0tkVM+ec/wtbS3px9a4Z9pMxYRSgFCL+W6LarsW8XsEKu3aYJuUwde0AYK7gw7Xe7WxMGPbjOILWqJR93/ZH8GJILgQLqSXOKkzgQQc4Jdp8DBtL5OjLj3zzwHff+PaR75/4/mm9feS773zzwF9/5ujJmI/P/CypGWM7Hd3PR5/HzRSf5WdtB0fRB/J0tDtOyVM82btr+PLDVmaPhC/vHN/eLeJl8BJWKSUiAgDvQ8lpMmmvMmDOuSi1aG2IYkrZGET0KWWiaK0jSinlvyE1UgSfeJPlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/e4706/iPad_Wizard_Installation_PoE_07.avif 230w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/d1af7/iPad_Wizard_Installation_PoE_07.avif 460w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/7f308/iPad_Wizard_Installation_PoE_07.avif 920w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/e1c99/iPad_Wizard_Installation_PoE_07.avif 1380w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/76ea5/iPad_Wizard_Installation_PoE_07.avif 1840w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/b105c/iPad_Wizard_Installation_PoE_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/a0b58/iPad_Wizard_Installation_PoE_07.webp 230w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/bc10c/iPad_Wizard_Installation_PoE_07.webp 460w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/966d8/iPad_Wizard_Installation_PoE_07.webp 920w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/445df/iPad_Wizard_Installation_PoE_07.webp 1380w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/78de1/iPad_Wizard_Installation_PoE_07.webp 1840w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/03b06/iPad_Wizard_Installation_PoE_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/81c8e/iPad_Wizard_Installation_PoE_07.png 230w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/08a84/iPad_Wizard_Installation_PoE_07.png 460w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/c0255/iPad_Wizard_Installation_PoE_07.png 920w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/b1001/iPad_Wizard_Installation_PoE_07.png 1380w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/161ec/iPad_Wizard_Installation_PoE_07.png 1840w", "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/49853/iPad_Wizard_Installation_PoE_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6747b5fa551c7e23f3b8d37e3f6732ea/c0255/iPad_Wizard_Installation_PoE_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b8c4966573ee5c092ca8b90962ee9aec/49853/iPad_Wizard_Installation_PoE_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz62Ty27EIAxF8/8/2UUzCybDAOFlG2yqhCYiXUxbtWdhyRdf2ZbwpB52eeh5ftdaA4CPebHZx4zwSSnFGGOtJaKuIKKz9u12n+zqU0oAkFIqpQJSJqksZYeIQgjdHGMsB1xrQp6eT621bgfCvDrrnBORruAOESFia0OhyOScCyFs2Q4zL8uilCKiusMD9YBrzVgnY4z3vrXGzN2slJrnGQB6eo7Q044wA/Fm1lrLATP3rU4FIMcYYgwpRhlglqnWSkSjeVisVW4RyIXsE4SEVcbHNrUr55AdKG3F5qD5PeZyNcuVL51f841ZjihD+k+dX+/8p7F/ZD5/2O86I2LOWWsdYyqEd4c3g08PRNsxGWNyztsZOee978XrunbxAyXDc6Fphtq/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b8c4966573ee5c092ca8b90962ee9aec/e4706/iPad_Wizard_Installation_PoE_08.avif 230w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/d1af7/iPad_Wizard_Installation_PoE_08.avif 460w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/7f308/iPad_Wizard_Installation_PoE_08.avif 920w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/e1c99/iPad_Wizard_Installation_PoE_08.avif 1380w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/76ea5/iPad_Wizard_Installation_PoE_08.avif 1840w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/b105c/iPad_Wizard_Installation_PoE_08.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b8c4966573ee5c092ca8b90962ee9aec/a0b58/iPad_Wizard_Installation_PoE_08.webp 230w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/bc10c/iPad_Wizard_Installation_PoE_08.webp 460w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/966d8/iPad_Wizard_Installation_PoE_08.webp 920w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/445df/iPad_Wizard_Installation_PoE_08.webp 1380w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/78de1/iPad_Wizard_Installation_PoE_08.webp 1840w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/03b06/iPad_Wizard_Installation_PoE_08.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b8c4966573ee5c092ca8b90962ee9aec/81c8e/iPad_Wizard_Installation_PoE_08.png 230w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/08a84/iPad_Wizard_Installation_PoE_08.png 460w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/c0255/iPad_Wizard_Installation_PoE_08.png 920w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/b1001/iPad_Wizard_Installation_PoE_08.png 1380w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/161ec/iPad_Wizard_Installation_PoE_08.png 1840w", "/en/static/b8c4966573ee5c092ca8b90962ee9aec/49853/iPad_Wizard_Installation_PoE_08.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b8c4966573ee5c092ca8b90962ee9aec/c0255/iPad_Wizard_Installation_PoE_08.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Wählen Sie Ihre Kamera aus der Liste aus. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/49853/iPad_Wizard_Installation_PoE_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQoz5WSSU5kMQyG3x1ZIiFxJSSO0AsEnANWoO4+AELMQypjxVMSo/dSVUxFAZ+ysCz/v53Ew8Oze3o219dXxhhEjHN48hgSIIzknInIWuucI6KcMwAgonP24uZxcCHmnBEx5yxSkDiBEIuIlFpbayISQvDel1Jaa7VWFqmlzKkOs5kxxuiS1toqzjmHidmE9z6EgIirgsF7n1Lqsm5sre0ZESEiWcLMRFRKeRVba2OMqlqnIUspzjlm1o304lFsjGlLaq3OuW73LUMphZnfij/Mtqnzh2xrDSdEZCxQ/XxUlVmYZWjvqbUCQEqJIGmVrzr3FVgj7p915fX8Vv/ejef/vf67W8Rnt3rplnf+Srx3olt/dOdQtw9091h3j8Zg53BM7p8uFmLNnfXHrOm8cBmNdO2DrfwX4tWG/a4zEQGAMWY+z8L06OnG0iwis9gJACAi732MsReHEHryBZ2saGd1Llc3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b978b276b6b46d1624d14c9bae5dd1ee/e4706/iPad_Wizard_Installation_PoE_09.avif 230w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/d1af7/iPad_Wizard_Installation_PoE_09.avif 460w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/7f308/iPad_Wizard_Installation_PoE_09.avif 920w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/e1c99/iPad_Wizard_Installation_PoE_09.avif 1380w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/76ea5/iPad_Wizard_Installation_PoE_09.avif 1840w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/b105c/iPad_Wizard_Installation_PoE_09.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b978b276b6b46d1624d14c9bae5dd1ee/a0b58/iPad_Wizard_Installation_PoE_09.webp 230w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/bc10c/iPad_Wizard_Installation_PoE_09.webp 460w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/966d8/iPad_Wizard_Installation_PoE_09.webp 920w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/445df/iPad_Wizard_Installation_PoE_09.webp 1380w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/78de1/iPad_Wizard_Installation_PoE_09.webp 1840w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/03b06/iPad_Wizard_Installation_PoE_09.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b978b276b6b46d1624d14c9bae5dd1ee/81c8e/iPad_Wizard_Installation_PoE_09.png 230w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/08a84/iPad_Wizard_Installation_PoE_09.png 460w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/c0255/iPad_Wizard_Installation_PoE_09.png 920w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/b1001/iPad_Wizard_Installation_PoE_09.png 1380w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/161ec/iPad_Wizard_Installation_PoE_09.png 1840w", "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/49853/iPad_Wizard_Installation_PoE_09.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b978b276b6b46d1624d14c9bae5dd1ee/c0255/iPad_Wizard_Installation_PoE_09.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06194f496da8de515ca0c0b755c45349/49853/iPad_Wizard_Installation_PoE_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WT3UrDMBSA+/J6I2PeTB9Eb8QLBTfY3mCg4IXbhV3T9DRtz0+THGm7DkTFDfEjHJKTfEkOJMl7Vuwys9m8GWOIyNW4A3Q1Eu5hZmttURQiMmSIqCjs6zZNitI1TUNETdO0rSeWCkVaH3u894joeogohBBj7GIINYckz40xRkdiCKrKzIMAAFmW5SNDBgBERFUTAKiqqtN6QggAkKapq2qktkFGbpFbki4iSY2MxN77TrbWOudU9XClPM+zbBdj0J+JMe5lY0wc6bfopm/Wevagk7lePOnlQqd9ZzLX80e9XQ++Jt57Efkq3z3rdKFXK50t9XrVtdmyG04Xev8yyt/e50iS+JkQ9qWGqP6HFuJv8p9OPkr+l5pPkA8v7LSTmRkRjTF13bTCKfDWcu5IpLU9w8cCAOfcsLgsyyH5AerAZ7B53ruSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06194f496da8de515ca0c0b755c45349/e4706/iPad_Wizard_Installation_PoE_10.avif 230w", "/en/static/06194f496da8de515ca0c0b755c45349/d1af7/iPad_Wizard_Installation_PoE_10.avif 460w", "/en/static/06194f496da8de515ca0c0b755c45349/7f308/iPad_Wizard_Installation_PoE_10.avif 920w", "/en/static/06194f496da8de515ca0c0b755c45349/e1c99/iPad_Wizard_Installation_PoE_10.avif 1380w", "/en/static/06194f496da8de515ca0c0b755c45349/76ea5/iPad_Wizard_Installation_PoE_10.avif 1840w", "/en/static/06194f496da8de515ca0c0b755c45349/b105c/iPad_Wizard_Installation_PoE_10.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06194f496da8de515ca0c0b755c45349/a0b58/iPad_Wizard_Installation_PoE_10.webp 230w", "/en/static/06194f496da8de515ca0c0b755c45349/bc10c/iPad_Wizard_Installation_PoE_10.webp 460w", "/en/static/06194f496da8de515ca0c0b755c45349/966d8/iPad_Wizard_Installation_PoE_10.webp 920w", "/en/static/06194f496da8de515ca0c0b755c45349/445df/iPad_Wizard_Installation_PoE_10.webp 1380w", "/en/static/06194f496da8de515ca0c0b755c45349/78de1/iPad_Wizard_Installation_PoE_10.webp 1840w", "/en/static/06194f496da8de515ca0c0b755c45349/03b06/iPad_Wizard_Installation_PoE_10.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06194f496da8de515ca0c0b755c45349/81c8e/iPad_Wizard_Installation_PoE_10.png 230w", "/en/static/06194f496da8de515ca0c0b755c45349/08a84/iPad_Wizard_Installation_PoE_10.png 460w", "/en/static/06194f496da8de515ca0c0b755c45349/c0255/iPad_Wizard_Installation_PoE_10.png 920w", "/en/static/06194f496da8de515ca0c0b755c45349/b1001/iPad_Wizard_Installation_PoE_10.png 1380w", "/en/static/06194f496da8de515ca0c0b755c45349/161ec/iPad_Wizard_Installation_PoE_10.png 1840w", "/en/static/06194f496da8de515ca0c0b755c45349/49853/iPad_Wizard_Installation_PoE_10.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06194f496da8de515ca0c0b755c45349/c0255/iPad_Wizard_Installation_PoE_10.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49365a08f9b259c4e56a06fdcf4883f5/49853/iPad_Wizard_Installation_PoE_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQoz6WS30pcMRCH87JeCYW+jfQVhKXYZ7A33or3tbvgOd3Jv5lkMsnkRI7rHipFKvjjI0yS+RICMdPR/znCfv8bAEoplIslZVFtXbWrLr0PxESUeh+qS2u9tY5Ij0/W+Ig551JKzllVuVSPlVJNqXiPAMG5OE0wz+AcWhtCoJyriHpSYy0AwDin9+7dKtSqKXGMRJSJmCgjJsTELDHSPAMimfUoojHGcg7AcZ7nZRkr45VTlmUtQ4iHw56IjHMOEf+WrQXEuPX/w+hdp2li5lUGgM1UVWt98P7uUXf3/cdDv3lYx63Y3fe7X80CEGWjqrXWTW5NQ+RMfHUrF9fty/d2uWtfb1Yud+v04rp9+1lLKj6wGW+j2mPkGFmZh5wpL5ynynzqMcvbtKanDR/YvYMP/5M/wufk99782ZtDyB+St0/SVRMyvuwhFsSytSJuxeuiERFmBoCUcqsyBznYClhrbdZ65wJzEakhIGISqcwlRmIWkfoMSztY0TUWdoIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49365a08f9b259c4e56a06fdcf4883f5/e4706/iPad_Wizard_Installation_PoE_11.avif 230w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/d1af7/iPad_Wizard_Installation_PoE_11.avif 460w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/7f308/iPad_Wizard_Installation_PoE_11.avif 920w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/e1c99/iPad_Wizard_Installation_PoE_11.avif 1380w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/76ea5/iPad_Wizard_Installation_PoE_11.avif 1840w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/b105c/iPad_Wizard_Installation_PoE_11.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49365a08f9b259c4e56a06fdcf4883f5/a0b58/iPad_Wizard_Installation_PoE_11.webp 230w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/bc10c/iPad_Wizard_Installation_PoE_11.webp 460w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/966d8/iPad_Wizard_Installation_PoE_11.webp 920w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/445df/iPad_Wizard_Installation_PoE_11.webp 1380w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/78de1/iPad_Wizard_Installation_PoE_11.webp 1840w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/03b06/iPad_Wizard_Installation_PoE_11.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49365a08f9b259c4e56a06fdcf4883f5/81c8e/iPad_Wizard_Installation_PoE_11.png 230w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/08a84/iPad_Wizard_Installation_PoE_11.png 460w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/c0255/iPad_Wizard_Installation_PoE_11.png 920w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/b1001/iPad_Wizard_Installation_PoE_11.png 1380w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/161ec/iPad_Wizard_Installation_PoE_11.png 1840w", "/en/static/49365a08f9b259c4e56a06fdcf4883f5/49853/iPad_Wizard_Installation_PoE_11.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49365a08f9b259c4e56a06fdcf4883f5/c0255/iPad_Wizard_Installation_PoE_11.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      